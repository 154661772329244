<template>
  <div class="w-full justify-center flex align-center !z-[10] absolute top-0 text-white gap-7 sm:visible invisible pt-2 bg-gray-800">
    <NuxtLink class="alink" to="/">Home</NuxtLink>
    <NuxtLink class="alink" to="/web-development">Web Development</NuxtLink>
    <NuxtLink class="alink" to="/branding">Brand Creation</NuxtLink>
    <NuxtLink class="alink" to="/contact">Contact</NuxtLink>
  </div>
  <!-- <div class="!z-[3] flex sm:flex-row justify-space-between px-8 bg-gray-800 text-white align-center py-2 w-full sticky top-0 left-0 right-0 flex-col relative">
    <p class="sm:visible invisible">One professional for all your website needs.</p>
    <p class="text-2xl font-bold">Ashley Strain</p>
    <div class="flex align-center !z-[10] absolute top-0 right-4 gap-7 sm:visible invisible mt-2">
      <NuxtLink to="/">Home</NuxtLink>
      <NuxtLink to="/web-development">Web Development</NuxtLink>
      <NuxtLink to="/branding">Brand Creation</NuxtLink>
      <NuxtLink to="/contact">Contact</NuxtLink>
    </div>
  </div> -->
  <div class="!z-[5] flex md:gap-[150px] flex-col sm:flex-row bg-gray-800 text-white pt-[50px] pb-[40px] px-10">
    <p data-aos="fade-right" data-aos-duration="3000" class="pb-2 sm:pb-0 text-5xl sm:text-7xl font-bold sm:whitespace-nowrap text-center sm:text-left"><slot name="PageTitle" /></p>
  </div>
</template>

<!-- 
<FullHeader>
  <template #PageTitle>
    Test
  </template>
</FullHeader> 
-->

<style>

.alink {
  display: inline-block;
  padding-bottom: 0px;
  position: relative;
  background-image: linear-gradient(to right, #f9dd94 0%, #f9dd94 100%);
  background-position: 0 100%; 
  background-size: 100% 5px; 
}
.alink:hover::before, 
  { width: 0% ;
}
.alink:hover::after {
  width: 100%;
}
/* .alink::before { 
  width: 100%; 
} */
.alink::after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 10px;
  left: 0;
  position: absolute;
  background: linear-gradient(to left, #f69ec4, #f9dd94 100%);
  transition: width .3s ease 0s, left .3s ease 0s;
  width: 0;
}
</style>